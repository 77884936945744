










import SharedCompaniesList from "@/components/Shared/Companies/List.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        SharedCompaniesList,
    },
    metaInfo() {
        const title = "Perusahaan";

        return {
            title: title,
        };
    },
})
export default class Companies extends Vue {}
