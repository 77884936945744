












































import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import SharedCompaniesListItem from "./ListItem.vue";

@Component({
    components: {
        BaseCard,
        SharedCompaniesListItem,
    },
})
export default class SharedCompaniesList extends Vue {
    @Prop() search!: string;

    get searchValue() {
        return this.search;
    }
    set searchValue(search) {
        this.$emit("input", search);
    }
}
